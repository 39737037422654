import React, { useEffect, useState } from "react";
import { Alert, Button, Image, Nav } from "react-bootstrap";
// import { Text } from 'react-native';
// import Global from "./Global";
import { ReactSession } from 'react-client-session';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const PaiementValide = ({ isinline, reloadPage }) => {

    const garage = ReactSession.get("garage");

    const [error, setError] = useState({ value: false, texte: '' });
    const [identifiant, setIdentifiant] = useState('');
    const [password, setPassword] = useState('');

    const [success, setSuccess] = useState(false);
    const [mail, setMail] = useState("");
    const [code, setCode] = useState("");

    const makeRequest = (mail) => {
        fetch(`https://webservices.k-lix.com/TribuPrd.aspx?action=getGarageAndAPIByMail&mail=${mail}`, {
            mode: 'cors',
            method: 'GET',
            //dataType: 'jsonp',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify({ action: 'getCsv', login: 'ravier', pwd: 'angel' })
        })
            .then((response) => {
                let reponse = response.json();
                return reponse; // << This is the problem
            })
            .then((data) => { // responseData = undefined
                // addTestToPage(data.title);
                let donnee = data.data;
                console.log(donnee);
                if (donnee.length === 1 && donnee[0].ACCES_INTERDIT === 0) {
                    setError({ value: false, texte: '' });
                    //ReactSession.set("garage", donnee[0]);
                    reloadPage(donnee[0]);
                } else reloadPage({});
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        let success = query.get("success");
        let mymail = query.get("mail")
        setSuccess(success);
        setMail(mymail);

        if (success) {
            let mycode = query.get("code")
            setCode(mycode);
            makeRequest(mymail);
            //validAccount(mymail, mycode)
        }

        if (query.get("canceled")) {
            alert("une erreur s'est produite")
        }
    }, []);

    return (
        <>
            {garage && garage.ID && success === 'true' && mail && code && (
                <Alert className="text-center" variant='primary'>
                    Félicitation ! Voici vos identifiants<br></br>
                    Votre mail est <b>{mail}</b> et votre code est <b>{code}</b>
                </Alert>
            )}
            {garage && garage.ID && success === "false" && mail && (
                <Alert className="text-center" variant='danger'>
                    Votre abonnement n'a pas aboutit
                </Alert>
            )}
            <div className="d-grid gap-2">
                <Nav style={{ margin: "auto" }} variant="pills" defaultActiveKey="/">
                    <Nav.Item>
                        <Nav.Link href="/">Retourner à la page d'accueil</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
        </>
    );
};

export default PaiementValide;