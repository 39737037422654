import React from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ReactSession } from 'react-client-session';

const Prix = ({ tarif, lien, par, type, libelle, garage }) => (
    <Card style={{ textAlign: "center", width: '18rem' }}>
        <Card.Img variant="top" src="/logo.png" style={{ width: "135px", margin: "auto", paddingTop: "10px" }} /><br></br>
        <Card.Img variant="top" src="/logo_az.png" style={{ width: "135px", margin: "auto", paddingTop: "0px", backgroundColor: "black" }} />
        <Card.Body>
            <Card.Title>Plan {type}<br></br>{libelle}</Card.Title>
            <Card.Text>
                <Table style={{ margin: "auto", width: "auto" }}>
                    <thead style={{ textAlign: "start", borderColor: "transparent" }}>
                        <tr>
                            <td style={{ fontSize: "2.1em", fontWeight: "bold", paddingRight: "3px", width: "30px" }}>
                                {tarif}&nbsp;€
                            </td>
                            <td style={{ fontSize: "1em", fontWeight: "bold", paddingLeft: "0px", paddingTop: "15px", width: "30px", verticalAlign: "initial" }}>
                                HT
                            </td>
                            <td style={{ color: "gray", lineHeight: "16px", verticalAlign: "top", paddingTop: "17px", paddingLeft: "0px", width: "30px" }}>
                                par<br></br>{par}
                            </td>
                        </tr>
                    </thead>
                </Table>
            </Card.Text>

            {garage && garage.ID && (
                // <form action={"https://localhost:44379/abonnement/" + lien + "/" + garage.MAIL} method="POST">
                <form action={"https://www.tribuapi.fr/abonnement/" + lien + "/" + garage.MAIL} method="POST">
                    <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-primary">
                            M'abonner
                        </button>
                    </div>
                </form>
            )}
            {!(garage && garage.ID) && (
                <>Connectez vous !</>
            )}
        </Card.Body>
    </Card>
);

function Tarifaz() {
    const garage = ReactSession.get("garage");
    return (
        <Container>

            {/* {!(garage && garage.ID) && (<>
                Important : Vous devez être adhérent à FFCTribu avant de vous abonner à l`interfaçage avec les DMS.<br></br>
            </>)} */}
            {/* Cette abonnement est indépendant de tout engagement que vous auriez auprès de la FFC.<br></br> */}
            {/* <Image style={{ height: "30px", margin: '-7px' }} src="/logo.png"></Image> */}
            <Row>
                <Col style={{ textAlign: "-webkit-center" }}>
                    <Prix tarif="10" par="mois" type="mensuel" libelle="1 mois" lien="moisaz" garage={garage}></Prix>
                </Col>
                <Col style={{ textAlign: "-webkit-center" }}>
                    <Prix tarif="60" par="semestre" type="semestriel" libelle="6 mois" lien="semestreaz" garage={garage}></Prix>
                </Col>
                <Col style={{ textAlign: "-webkit-center" }}>
                    <Prix tarif="120" par="an" type="annuel" libelle="12 mois" lien="annee" garage={garage}></Prix>
                </Col>
            </Row>
        </Container>
    );
}

export default Tarifaz;