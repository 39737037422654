import React, { useEffect, useState } from "react";
import { Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ReactSession } from 'react-client-session';
import Moment from 'react-moment';

function Menu({reloadPage}) {

    // const [garage, setGarage] = useState(ReactSession.get("garage"));

    // // useEffect(() => {
    // //     setGarage(ReactSession.get("garage"));
    // // }, [ReactSession.get("garage")]);

    const garage = ReactSession.get("garage");
    const deconn = () => {
        ReactSession.set("garage", {});
        reloadPage({});
    };
    const dateToFormat = new Date();
    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                <Container>
                    <Navbar.Brand href="/"><Image style={{ height: "30px", margin: '-7px' }} src="/logo.png"></Image></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Accueil</Nav.Link>
                            {/* <Nav.Link href="/tarif">Tarif</Nav.Link> */}
                            {/* <Nav.Link href="/abonnezvous">Abonnez-vous</Nav.Link> */}
                            {/* <Nav.Link href="/dms">DMS</Nav.Link> */}
                            {/* <Nav.Link href="/nous">Qui sommes nous ?</Nav.Link> */}
                            {/* <Nav.Link href="/nous">
                            <Moment format="YYYY-MM-DD HH:mm:ss" date={dateToFormat} />
                        </Nav.Link> */}
                            {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown> */}
                        </Nav>
                        {garage && garage.ID && (
                            <Nav>
                                <Navbar.Text style={{ color: 'black' }}>{garage.NOM}</Navbar.Text>
                                <Nav.Link onClick={deconn}>
                                    Déconnexion
                                </Nav.Link>
                            </Nav>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar >
        </>
    );
}

export default Menu;