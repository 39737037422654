import './App.css';
import React, { useState } from "react";
import Connection from './Components/Connection';
import Menu from './Components/Menu';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import Tarif from './Components/Tarif';
import QuiSommesNous from './Components/QuiSommesNous';
import Accueil from './Components/Accueil';
import { ReactSession } from 'react-client-session';
import PaiementValide from './Components/PaiementValide';
import { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import Azparebriz from './Components/Azparebriz';

function App() {
    ReactSession.setStoreType("localStorage");
    const [garage, setGarage] = useState(ReactSession.get("garage"));
    // const [garageSel, setGarageSel] = useState({});
    const reloadPage = (garage) => {
        ReactSession.set("garage", garage);
        setGarage(garage)
    };

    const [success, setSuccess] = useState(false);
    const [mail, setMail] = useState("");
    const [code, setCode] = useState("");
    const [error, setError] = useState({ value: false, texte: '' });

    const makeRequest = (mail) => {
        fetch(`https://webservices.k-lix.com/TribuPrd.aspx?action=getGarageAndAPIByMail&mail=${mail}`, {
            mode: 'cors',
            method: 'GET',
            //dataType: 'jsonp',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify({ action: 'getCsv', login: 'ravier', pwd: 'angel' })
        })
            .then((response) => {
                let reponse = response.json();
                return reponse; // << This is the problem
            })
            .then((data) => { // responseData = undefined
                // addTestToPage(data.title);
                let donnee = data.data;
                console.log(donnee);
                if (donnee.length === 1 && donnee[0].ACCES_INTERDIT === 0) {
                    setError({ value: false, texte: '' });
                    //ReactSession.set("garage", donnee[0]);
                    reloadPage(donnee[0]);
                } else reloadPage({});
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        let success = query.get("success");


        if (success) {
            let mymail = query.get("mail")
            let mycode = query.get("code")

            setSuccess(success);
            setMail(mymail);
            setCode(mycode);
            makeRequest(mymail);
            //validAccount(mymail, mycode)
        }

        if (query.get("canceled")) {
            alert("une erreur s'est produite")
        }
    }, []);


    return (
        <>
            <Menu reloadPage={reloadPage}></Menu>
            <Connection isinline={true} reloadPage={reloadPage}></Connection>
            {garage && garage.ID && success === 'true' && mail && code && (
                <Alert className="text-center" variant='primary'>
                    Félicitation ! Voici vos identifiants<br></br>
                    Votre mail est <b>{mail}</b> et votre code est <b>{code}</b>
                </Alert>
            )}
            {garage && garage.ID && success === "false" && mail && (
                <Alert className="text-center" variant='danger'>
                    Votre abonnement n'a pas aboutit
                </Alert>
            )}
            <Router>
                <div>
                    <Routes>
                        <Route path="/tarif" element={<Tarif reloadPage={reloadPage} />} />
                        <Route path="/conn" element={<Connection reloadPage={reloadPage} />} />
                        <Route path="/users" element={<Menu reloadPage={reloadPage} />} />
                        <Route path="/nous" element={<QuiSommesNous reloadPage={reloadPage} />} />
                        <Route path="/" element={<Accueil reloadPage={reloadPage} />} />
                        <Route path="/valide" element={<PaiementValide reloadPage={reloadPage} />} />
                        <Route path="/az" element={<Azparebriz reloadPage={reloadPage} />} />
                    </Routes>
                </div>
            </Router>
            {/* <div className="App">
                {!garageSel.ID && (<Connection setGarageSel={setGarageSel}></Connection>)}                
            </div> */}
            {/* <stripe-pricing-table pricing-table-id="prctbl_1M7EOAInmw2i7Qt0r68hjW2V" publishable-key="pk_test_QmvFTKvOjxNslGr4BJkHrU4S">
            </stripe-pricing-table>
            {message ? (
                <Message message={message} />
            ) : (
                <ProductDisplay />
            )} */}
        </>
    );
}

export default App;
