import React, { useEffect, useState } from "react";
import { Alert, Card, Container, Image } from "react-bootstrap";
// import { Text } from 'react-native';
// import Global from "./Global";
import { ReactSession } from 'react-client-session';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import moment from 'moment';

const Connection = ({ isinline, reloadPage }) => {

    const garage = ReactSession.get("garage");

    const [error, setError] = useState({ value: false, texte: '' });
    const [identifiant, setIdentifiant] = useState('carnot');
    const [password, setPassword] = useState('P@ssword');

    const makeRequest = () => {
        fetch(`https://webservices.k-lix.com/TribuPrd.aspx?action=getGarageWithSameMail&login=${identifiant}&pwd=${password}`, {
            mode: 'cors',
            method: 'GET',
            //dataType: 'jsonp',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify({ action: 'getCsv', login: 'ravier', pwd: 'angel' })
        })
            .then((response) => {
                let reponse = response.json();
                return reponse; // << This is the problem
            })
            .then((data) => { // responseData = undefined
                // addTestToPage(data.title);
                let donnee = data.data;
                console.log(donnee);
                if (donnee.length === 0)
                    setError({ value: true, texte: `Cet identifiant associé à ce mot de passe n'existe pas dans FFC Tribu` });
                else if (donnee.length > 1)
                    setError({ value: true, texte: `L'adresse mail utilisée par ce compte est utilisée par plusieurs garages merci de contacter la FFC` });
                else if (donnee[0].ACCES_INTERDIT === 1)
                    setError({ value: true, texte: `Cette compte est désactivée merci de contacter la FFC` });
                else {
                    setError({ value: false, texte: '' });
                    //ReactSession.set("garage", donnee[0]);
                    reloadPage(donnee[0]);
                }
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    const isEmptyOrSpaces = (str) => { return str === null || str.match(/^ *$/) !== null; };

    const onkeyup = (event) => {
        if (event.charCode === 13 && !isEmptyOrSpaces(password) && !isEmptyOrSpaces(identifiant)) {
            makeRequest();
        } else {
            setError({ value: false, texte: '' });
        }
    };

    return (
        <>
            {garage && garage.ID && (
                <Alert className="text-center" variant='primary'>
                    Vous possédez un compte FFC-Tribu dont le mail est <b>{garage.MAIL}</b>
                    {garage && garage.ID && garage.ISACTIF && (moment(garage.DATE_FIN + "T23:59:59").isSameOrAfter(moment())) && (
                        <>
                            <br></br>
                            Votre Garage est abonné jusqu'au <span style={{ fontWeight: "bold" }}>
                                {moment(garage.DATE_FIN + "T23:59:59").format("DD/MM/YYYY")}
                            </span> votre code est <span style={{ fontWeight: "bold" }}>{garage.CODE}</span>
                        </>
                    )}
                </Alert>)}
            {isinline && !(garage && garage.ID) && (

                <Container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <Card style={{ textAlign: "center" }}>
                        <Card.Body>
                            <Card.Text>
                                <div className="container row" style={{ margin: "auto" }}>
                                    <div className="col-1" style={{ margin: "auto" }}>
                                        <Image style={{ width: "75px" }} src="/tribuffc.png"></Image>
                                    </div>
                                    <div className="col-11">
                                        <div className="container row" style={{ margin: "auto", paddingTop: '20px' }}>
                                            <div className="col-12 text-center"  >
                                                Connectez vous avec vos identifiant FFC Tribu.<br></br><br></br>
                                            </div>
                                            <div className="col-md-5">
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>Identifiant</InputGroup.Text>
                                                    <Form.Control aria-label="login" placeholder="Identifiant"
                                                        onChange={(e) => setIdentifiant(e.target.value)}
                                                        onKeyPress={onkeyup}
                                                        autocomplete="off" />
                                                </InputGroup>
                                            </div>
                                            <div className="col-md-5">
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>Mot de passe</InputGroup.Text>
                                                    <Form.Control type="password" aria-label="Mot de passe" placeholder="Mot de passe"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        onKeyPress={onkeyup} />
                                                </InputGroup>
                                            </div>
                                            <div className="col-md-2 d-grid gap-2">
                                                <button style={{ height: "fit-content" }} type="submit" className="btn btn-primary" onClick={makeRequest}>
                                                    Connexion
                                                </button>
                                            </div>
                                            <div className="col-12">
                                                {(error.value) && (<Alert variant='danger'>
                                                    {error.texte}
                                                </Alert>)}
                                            </div>
                                            <div className="col-12 text-center"  >
                                                Ce service n'est disponible que pour les adhérents de la FFC-Carrosserie ayant souscrit à FFC Tribu.<br></br>
                                                Si vous n'êtes pas adhérents de la FFC-Carrosserie merci de prendre contact avec eux.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            )}
            {!isinline && (
                <div className="Auth-form-container">
                    {/* {identifiant} */}
                    <div className="Auth-form">
                        <div className="Auth-form-content">
                            <div className="form-group mt-3">
                                <label>Identifiant</label>
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    placeholder="identifiant"
                                    onChange={(e) => setIdentifiant(e.target.value)}
                                    onKeyPress={onkeyup}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label>Mot de passe</label>
                                <input
                                    type="password"
                                    className="form-control mt-1"
                                    placeholder="Mot de passe"
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyPress={onkeyup}
                                />
                            </div>
                            {(error.value) && (<><br /><Alert variant='danger'>
                                {error.texte}
                            </Alert>
                            </>
                            )}

                            <div className="d-grid gap-2 mt-3">
                                <button type="submit" className="btn btn-primary" onClick={makeRequest}>
                                    Connexion
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Connection;