import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Connection from './Connection';
import { ReactSession } from 'react-client-session';
import Tarif from './Tarif';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import Tarifaz from './Tarifaz';

function Accueil({ reloadPage }) {

    const garage = ReactSession.get("garage");

    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        if (garage && garage.ID) handleClose();
    }, [garage]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    return (
        <>
            <Container style={{ textAlign: "center" }}>
                {/* {garage && garage.ID && garage.ISACTIF && (
                    <>
                        Votre Garage est déjà abonné jusqu'au {garage.DATE_FIN} {moment(garage.DATE_FIN).format("DD/MM/YYYY HH:mm:ss")} et il est {moment().format("DD/MM/YYYY HH:mm:ss")}
                    </>
                )} */}
                {garage && garage.ID && garage.ISACTIF && (moment(garage.DATE_FIN + "T23:59:59").isSameOrAfter(moment())) && (
                    <>
                        {/* Votre Garage est déjà abonné jusqu'au <span style={{ fontWeight: "bold" }}>
                            {moment(garage.DATE_FIN + "T23:59:59").format("DD/MM/YYYY")}
                        </span> votre code est <span style={{ fontWeight: "bold" }}>{garage.CODE}</span> */}
                        <br></br>Vous pouvez prolonger votre abonnement en vous abonnant ci-dessous<br></br><br></br>
                    </>
                )}
                {garage && garage.ID && !(garage.ISACTIF && moment(garage.DATE_FIN + "T23:59:59").isSameOrAfter(moment())) && (
                    <>
                        Votre Garage n'est pas abonné
                    </>
                )}
                {searchParams.get("partenaire") === 'az' && (
                    <Tarifaz></Tarifaz>
                )}
                {searchParams.get("partenaire") !== 'az' && (
                    <Tarif></Tarif>
                )}
                <br></br>
                Une saisie unique dans votre DMS. <br></br>
                Fini les doubles saisies. <br></br>
                Tout est centralisé.<br></br>
                <br></br>
                Les dossiers générés sont dupliqués automatiquement dans FFCTribu et y sont accessible immédiatement<br></br>
                {/* <Chatbot reloadPage={reloadPage}></Chatbot> */}
                <br></br>
                <a href="mailto:contact@k-lix.com;subject:Tribu API">contact@k-lix.com</a><br />

            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Identification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Connection reloadPage={reloadPage}></Connection>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    );
}

export default Accueil;