import React from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

function QuiSommesNous() {
    return (
        <Container style={{ paddingTop: "50px" }}>
            Nous ne sommes pas la FFC<br></br>
            K-Lix est une société de développement de site applicatif tel que FFCTribu.<br></br>
            Nous sommes prestataire de la Fédération française de la carrosserie (FFC).<br></br>
            A ce titre nous avons reçu l`accord de développer, indépendamment de la FFC, un interfaçage entre les DMS et FFCTribu.<br></br>
            <Card>
                <Card.Header style={{ textAlign: "center" }}><h3>Abonnement</h3></Card.Header>
                <Card.Body>
                    <Table striped bordered hover>
                        <thead style={{ textAlign: "center", borderColor: "transparent" }}>
                            <tr>
                                <td style={{ width: "50%", borderRight: "1px solid gray" }}>
                                    <blockquote className="blockquote mb-0">
                                        <span >
                                            {' '}
                                            13€ HT / mois{' '}
                                        </span >
                                        <br></br>
                                        {/* <span style={{ fontSize: ".8rem", color: "gray" }}>
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </span> */}
                                    </blockquote>
                                </td>
                                <td>
                                    <blockquote className="blockquote mb-0">
                                        <span >
                                            {' '}
                                            120€ HT / an{' '}
                                        </span >
                                        <br></br>
                                        <span style={{ fontSize: ".8rem", color: "gray" }}>
                                            (10 € HT /mois)
                                        </span>
                                    </blockquote>
                                </td>
                            </tr>
                        </thead>

                    </Table>
                </Card.Body>
            </Card>

        </Container>
    );
}

export default QuiSommesNous;